// extracted by mini-css-extract-plugin
export var active = "bx_bd";
export var categories = "bx_cD";
export var category = "bx_cF";
export var icon = "bx_bv";
export var icons = "bx_bw";
export var image = "bx_g";
export var member = "bx_fZ";
export var memberTitle = "bx_f0";
export var name = "bx_fV";
export var placeholder = "bx_bb";
export var section = "bx_d";
export var show = "bx_L";
export var subtitle = "bx_ch";
export var team = "bx_fY";