// extracted by mini-css-extract-plugin
export var answer = "bt_fD";
export var arrow = "bt_9";
export var body = "bt_c";
export var category = "bt_cF";
export var contactIcons = "bt_fw";
export var current = "bt_fk";
export var fade = "bt_fz";
export var footer = "bt_c7";
export var footerText = "bt_cS";
export var icon = "bt_bv";
export var iconContainer = "bt_b3";
export var issue = "bt_fC";
export var issueContainer = "bt_fy";
export var issueText = "bt_fB";
export var issues = "bt_fx";
export var left = "bt_bN";
export var padding = "bt_dt";
export var right = "bt_bX";
export var section = "bt_d";
export var selectors = "bt_d6";
export var subtitle = "bt_ch";
export var title = "bt_h";