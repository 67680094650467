// extracted by mini-css-extract-plugin
export var buttons = "V_cn";
export var column = "V_b5";
export var columns = "V_b4";
export var container = "V_b";
export var down = "V_c3";
export var download = "V_c2";
export var footer = "V_c7";
export var information = "V_c1";
export var section = "V_d";
export var show = "V_L";
export var text = "V_ct";
export var title = "V_h";
export var units = "V_c4";
export var up = "V_c6";
export var upload = "V_c5";
export var waveOne = "V_q";
export var waveThree = "V_c0";
export var waveTwo = "V_n";
export var waves = "V_cZ";