// extracted by mini-css-extract-plugin
export var bottom = "bv_s";
export var contact = "bv_fG";
export var faqCat = "bv_fP";
export var faqCatDescription = "bv_fS";
export var faqCatTitle = "bv_fR";
export var faqCategories = "bv_fN";
export var faqIcon = "bv_fQ";
export var footerText = "bv_cS";
export var icon = "bv_bv";
export var iconContainer = "bv_b3";
export var iconText = "bv_fJ";
export var immediate = "bv_fH";
export var inputContainer = "bv_cG";
export var linksSubtitle = "bv_fL";
export var linksTitle = "bv_fK";
export var search = "bv_cz";
export var searchInput = "bv_cH";
export var section = "bv_d";
export var selected = "bv_bg";
export var supportButton = "bv_fT";
export var supportLinks = "bv_fM";
export var top = "bv_l";
export var wave = "bv_fF";