// extracted by mini-css-extract-plugin
export var background = "bJ_z";
export var backgroundContainer = "bJ_fr";
export var bottomWave = "bJ_cR";
export var buttons = "bJ_cn";
export var container = "bJ_b";
export var content = "bJ_bB";
export var icon = "bJ_bv";
export var iconContainer = "bJ_b3";
export var middleWave = "bJ_cQ";
export var negativeTop = "bJ_gZ";
export var overlap = "bJ_g1";
export var section = "bJ_d";
export var selected = "bJ_bg";
export var tab = "bJ_dC";
export var tabText = "bJ_dB";
export var title = "bJ_h";
export var topWave = "bJ_g0";