// extracted by mini-css-extract-plugin
export var buttons = "bb_cn";
export var container = "bb_b";
export var content = "bb_bB";
export var icon = "bb_bv";
export var iconContainer = "bb_b3";
export var image = "bb_g";
export var section = "bb_d";
export var selected = "bb_bg";
export var tab = "bb_dC";
export var tabText = "bb_dB";