// extracted by mini-css-extract-plugin
export var body = "bw_c";
export var buttons = "bw_cn";
export var container = "bw_b";
export var icon = "bw_bv";
export var icons = "bw_bw";
export var image = "bw_g";
export var items = "bw_fW";
export var label = "bw_ck";
export var mobileButtons = "bw_fX";
export var name = "bw_fV";
export var section = "bw_d";
export var selected = "bw_bg";
export var show = "bw_L";
export var title = "bw_h";