// extracted by mini-css-extract-plugin
export var bottomCircle = "bH_gT";
export var content = "bH_bB";
export var description = "bH_dd";
export var hide = "bH_C";
export var icon = "bH_bv";
export var image = "bH_g";
export var imageContainer = "bH_gY";
export var left = "bH_bN";
export var nextYear = "bH_gX";
export var right = "bH_bX";
export var section = "bH_d";
export var shrink = "bH_gW";
export var title = "bH_h";
export var topCircle = "bH_gS";
export var wave = "bH_fF";
export var waveContainer = "bH_dT";
export var year = "bH_gV";