// extracted by mini-css-extract-plugin
export var buttons = "bl_cn";
export var container = "bl_b";
export var image = "bl_g";
export var innerText = "bl_ff";
export var logo = "bl_bn";
export var p = "bl_dH";
export var section = "bl_d";
export var short = "bl_dJ";
export var tall = "bl_fg";
export var textContainer = "bl_b1";