// extracted by mini-css-extract-plugin
export var arrow = "bk_9";
export var body = "bk_c";
export var circle = "bk_fc";
export var customer = "bk_fb";
export var feedback = "bk_d9";
export var feedbacks = "bk_d7";
export var footer = "bk_c7";
export var footerText = "bk_cS";
export var left = "bk_bN";
export var nameLocation = "bk_fd";
export var padding = "bk_dt";
export var right = "bk_bX";
export var section = "bk_d";
export var selected = "bk_bg";
export var selectors = "bk_d6";
export var subtitle = "bk_ch";
export var text = "bk_ct";
export var title = "bk_h";
export var wrapper = "bk_d8";