// extracted by mini-css-extract-plugin
export var container = "P_b";
export var content = "P_bB";
export var icon = "P_bv";
export var iconContainer = "P_b3";
export var icons = "P_bw";
export var image = "P_g";
export var label = "P_ck";
export var section = "P_d";
export var text = "P_ct";
export var title = "P_h";