// extracted by mini-css-extract-plugin
export var active = "bf_bd";
export var arrow = "bf_9";
export var button = "bf_cd";
export var buttonMobile = "bf_dS";
export var column = "bf_b5";
export var columns = "bf_b4";
export var container = "bf_b";
export var description = "bf_dd";
export var disclaimer = "bf_cw";
export var disclaimerButton = "bf_dN";
export var disclaimerContainer = "bf_dV";
export var disclaimerText = "bf_dY";
export var fadeBottom = "bf_dX";
export var fadeTop = "bf_dW";
export var featureFooter = "bf_dQ";
export var footer = "bf_c7";
export var footerMobile = "bf_dR";
export var heading = "bf_G";
export var highlight = "bf_dP";
export var icon = "bf_bv";
export var iconContainer = "bf_b3";
export var left = "bf_bN";
export var padding = "bf_dt";
export var popular = "bf_ds";
export var prefix = "bf_dM";
export var recommend = "bf_dK";
export var recommended = "bf_dL";
export var right = "bf_bX";
export var section = "bf_d";
export var show = "bf_L";
export var subHeading = "bf_H";
export var suffix = "bf_dq";
export var title = "bf_h";
export var waveContainer = "bf_dT";
export var waveOne = "bf_q";
export var waveThree = "bf_c0";
export var waveTwo = "bf_n";
export var waves = "bf_cZ";