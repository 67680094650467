// extracted by mini-css-extract-plugin
export var container = "bD_b";
export var feature = "bD_gM";
export var featureText = "bD_gN";
export var features = "bD_gL";
export var footer = "bD_c7";
export var footerText = "bD_cS";
export var icon = "bD_bv";
export var section = "bD_d";
export var text = "bD_ct";
export var title = "bD_h";
export var waveOne = "bD_q";
export var waveThree = "bD_c0";
export var waveTwo = "bD_n";
export var waves = "bD_cZ";