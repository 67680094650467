// extracted by mini-css-extract-plugin
export var bottomContainer = "S_cY";
export var buttons = "S_cn";
export var icon = "S_bv";
export var link = "S_bt";
export var section = "S_d";
export var social = "S_cX";
export var text = "S_ct";
export var title = "S_h";
export var topContainer = "S_cW";
export var videoContainer = "S_cT";
export var videoWrapper = "S_cV";