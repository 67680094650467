// extracted by mini-css-extract-plugin
export var caption = "bG_gR";
export var grey = "bG_dz";
export var heading = "bG_G";
export var image = "bG_g";
export var left = "bG_bN";
export var reverse = "bG_dw";
export var right = "bG_bX";
export var section = "bG_d";
export var video = "bG_gm";
export var videoContainer = "bG_cT";
export var videoWrapper = "bG_cV";