// extracted by mini-css-extract-plugin
export var appStores = "Z_dy";
export var grey = "Z_dz";
export var icon = "Z_bv";
export var iconGroup = "Z_dx";
export var image = "Z_g";
export var left = "Z_bN";
export var reverse = "Z_dw";
export var right = "Z_bX";
export var section = "Z_d";
export var textSection = "Z_dv";