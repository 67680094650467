// extracted by mini-css-extract-plugin
export var buttons = "bK_cn";
export var left = "bK_bN";
export var media = "bK_g4";
export var right = "bK_bX";
export var section = "bK_d";
export var selected = "bK_bg";
export var signature = "bK_g3";
export var testimonial = "bK_g2";
export var text = "bK_ct";
export var textContainer = "bK_b1";
export var video = "bK_gm";