// extracted by mini-css-extract-plugin
export var background = "br_z";
export var backgroundContainer = "br_fr";
export var bottomImage = "br_t";
export var bottomImageContainer = "br_fv";
export var buttons = "br_cn";
export var container = "br_b";
export var imagesContainer = "br_ft";
export var middleWave = "br_cQ";
export var section = "br_d";
export var text = "br_ct";
export var textContainer = "br_b1";
export var title = "br_h";
export var topImage = "br_m";
export var waveOverlay = "br_fs";