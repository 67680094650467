// extracted by mini-css-extract-plugin
export var button = "bg_cd";
export var categories = "bg_cD";
export var footerText = "bg_cS";
export var input = "bg_cB";
export var map = "bg_dc";
export var mapContainer = "bg_db";
export var offered = "bg_c8";
export var search = "bg_cz";
export var section = "bg_d";
export var title = "bg_h";