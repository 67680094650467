// extracted by mini-css-extract-plugin
export var bottomWave = "bp_cR";
export var button = "bp_cd";
export var buttonContainer = "bp_N";
export var buttonFooter = "bp_fm";
export var container = "bp_b";
export var middleWave = "bp_cQ";
export var section = "bp_d";
export var subtitle = "bp_ch";
export var textContainer = "bp_b1";
export var title = "bp_h";